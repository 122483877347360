export const FILTER_NONE = 0;
export const INTRO = 10;
export const REVIEWING = 20;
export const INTERESTED = 30;
export const PASSED = 40;
export const DO_NOT_CONTACT = 50;
export const CONTACTED = 60;
export const MEETING_SCHEDULED = 70;
export const DUE_DILIGENCE = 80;
export const PASSED_POST_MEETING = 90;
export const ORDER_INDICATED = 100;

export const TARGETING_HCW_STATUSES = [INTRO, REVIEWING, INTERESTED, PASSED, DO_NOT_CONTACT, CONTACTED, MEETING_SCHEDULED, DUE_DILIGENCE, PASSED_POST_MEETING, ORDER_INDICATED];

export const NONE_LABEL = "[None]";
export const INTRO_LABEL = "Intro";
export const REVIEWING_LABEL = "Reviewing";
export const INTERESTED_LABEL = "Interested";
export const PASSED_LABEL = "Passed";
export const DO_NOT_CONTACT_LABEL = "Do not contact";
export const CONTACTED_LABEL = "Contacted";
export const MEETING_SCHEDULED_LABEL = "Meeting scheduled";
export const DUE_DILIGENCE_LABEL = "Due diligence";
export const PASSED_POST_MEETING_LABEL = "Passed post meeting";
export const ORDER_INDICATED_LABEL = "Order indicated";

export const TARGETING_HCW_STATUS_OPTIONS = [
    {value: FILTER_NONE, label: NONE_LABEL},
    {value: INTRO, label: INTRO_LABEL},
    {value: REVIEWING, label: REVIEWING_LABEL},
    {value: INTERESTED, label: INTERESTED_LABEL},
    {value: PASSED, label: PASSED_LABEL},
    {value: DO_NOT_CONTACT, label: DO_NOT_CONTACT_LABEL},
    {value: CONTACTED, label: CONTACTED_LABEL},
    {value: MEETING_SCHEDULED, label: MEETING_SCHEDULED_LABEL},
    {value: DUE_DILIGENCE, label: DUE_DILIGENCE_LABEL},
    {value: PASSED_POST_MEETING, label: PASSED_POST_MEETING_LABEL},
    {value: ORDER_INDICATED, label: ORDER_INDICATED_LABEL},
];

export const OPEN_HCW_STATUSES = [
    {value: FILTER_NONE, label: NONE_LABEL},
    {value: INTRO, label: INTRO_LABEL},
    {value: REVIEWING, label: REVIEWING_LABEL},
    {value: INTERESTED, label: INTERESTED_LABEL},
    {value: CONTACTED, label: CONTACTED_LABEL},
    {value: MEETING_SCHEDULED, label: MEETING_SCHEDULED_LABEL},
    {value: DUE_DILIGENCE, label: DUE_DILIGENCE_LABEL},
    {value: ORDER_INDICATED, label: ORDER_INDICATED_LABEL},
];
export const CLOSED_HCW_STATUSES = [
    {value: PASSED, label: PASSED_LABEL},
    {value: DO_NOT_CONTACT, label: DO_NOT_CONTACT_LABEL},
    {value: PASSED_POST_MEETING, label: PASSED_POST_MEETING_LABEL},
];

export const TARGETING_HCW_STATUS_LABELS = {
    0: NONE_LABEL,
    10: INTRO_LABEL,
    20: REVIEWING_LABEL,
    30: INTERESTED_LABEL,
    40: PASSED_LABEL,
    50: DO_NOT_CONTACT_LABEL,
    60: CONTACTED_LABEL,
    70: MEETING_SCHEDULED_LABEL,
    80: DUE_DILIGENCE_LABEL,
    90: PASSED_POST_MEETING_LABEL,
    100: ORDER_INDICATED_LABEL,
}
