export const SEVERITY_LOW = 10;
export const SEVERITY_MEDIUM = 20;
export const SEVERITY_HIGH = 30;

export const SEVERITY_BLACKLIST = 40;

export const SEVERITY_TIERS = [
    {value: SEVERITY_LOW, label: 'Low'},
    {value: SEVERITY_MEDIUM, label: 'Medium'},
    {value: SEVERITY_HIGH, label: 'High'},
    {value: SEVERITY_BLACKLIST, label: 'Blacklist'},
];
