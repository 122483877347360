export function convertFmpShortExchangeNameToGoogle(fmpName) {
    switch (fmpName) {
        // Handle stock exchange names (next step with ticker: ALACT.PA => ALACT:EPA)
        case "AMEX":
            return "NYSEAMERICAN";
        case "PNK":
            return "OTCMKTS";
        default:
            return fmpName;
    }
}

export function getGoogleFinanceUrl(ticker, stockExchange) {
    if (ticker && stockExchange) {
        // Convert financialmodellingprep conversion to Google stock exchange
        const googleExchange = convertFmpShortExchangeNameToGoogle(stockExchange);
        return `https://www.google.com/finance/quote/${ticker}:${googleExchange}`;
    } else {
        return false;
    }
}

export function getYahooFinanceUrl(ticker) {
    return `https://www.yahoo.com/finance/quote/${ticker}`;
}
