export const FROM_CONTACT_TO_CONTACT = 1;
export const FROM_CONTACT_TO_COMPANY = 2;
export const FROM_COMPANY_TO_CONTACT = 3;
export const FROM_COMPANY_TO_COMPANY = 4;

export default {
    methods: {
        isWithCompany(relationshipTypeDirection) {
            console.log("rtd", relationshipTypeDirection);
            return [FROM_CONTACT_TO_COMPANY, FROM_COMPANY_TO_CONTACT, FROM_COMPANY_TO_COMPANY].includes(relationshipTypeDirection);
        },
        isWithContact(relationshipTypeDirection) {
            console.log("rtd", relationshipTypeDirection);
            return [FROM_CONTACT_TO_CONTACT, FROM_CONTACT_TO_COMPANY, FROM_COMPANY_TO_CONTACT].includes(relationshipTypeDirection);
        },
    }
}
