import moment from "moment/moment";
import modals from "./modals";

export default {
    mixins: [modals],
    methods: {
        requestStaffing(myModalEl) {
            this.modal = new this.$bootstrap.Modal(myModalEl)
            this.modal.show();
        },
        /**
         * Submits a staffing request, user wants to be added.
         * @param companyId
         * @param contactId
         * @param roleId
         */
        doRequestStaffing(companyId, contactId, roleId) {

            const url = companyId ? '/api/user_manages_companies/add' : '/api/user_manages_contacts/add';

            this.loading = true;
            this.$axios.post(url, {
                roleId,
                companyId,
                contactId,
                userId: this.userInfo.id,
                confirmed: false
            })
                .then((response) => {
                    if (this.modal) {
                        this.closeModal(this.modal);
                        this.modal = null;
                    }

                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Staffing updated",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });

                    if(this.hasOwnProperty("updateTable")) {
                        this.updateTable = new Date();
                    }

                }).finally(() => {
                this.loading = false;
            });

        },
        doRequestDeleteStaffing(id, companyId, contactId) {
            // FIXME
            this.staffingLoading = id;
            this.$axios.post("/api/staffing/remove", {
                type: companyId > 0 ? "company" : "contact",
                id,
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Request sent",
                    message: "Your request to be removed from staffing has been sent and will be processed as soon as possible",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })

                this.companiesUpdated = moment().valueOf();
            }).finally(() => {
                this.staffingLoading = null;
            })
        },
    }
}
