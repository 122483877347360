export default {
    data() {
        return {
            addressAttributes: ['country', 'state', 'city', 'zip', 'address2', 'address1'],
        }
    },
    methods: {
        addressSuggestionForContact(contact) {
            if (!contact.company) {
                return null;
            }

            return this.getAddressSuggestion(contact, contact.company);
        },
        addressSuggestionsForCompany(company, contacts) {
            if (!contacts) {
                return [];
            }

            const addressSuggestions = [];

            for (const contact of contacts) {
                const suggestion = this.getAddressSuggestion(company, contact);

                if (suggestion) {
                    let suggestionAlreadyExists = false;
                    for (const addressSuggestion of addressSuggestions) {
                        if (addressSuggestion.suggestion === suggestion) {
                            addressSuggestion.contacts.push(contact);
                            suggestionAlreadyExists = true;
                            break;
                        }
                    }
                    if (!suggestionAlreadyExists) {
                        addressSuggestions.push({
                            suggestion,
                            contacts: [contact],
                        })
                    }
                }
            }

            for (const addressSuggestion of addressSuggestions) {
                if (addressSuggestion.contacts.length <= 1) {
                    addressSuggestion.label = `From ${addressSuggestion.contacts[0].name}: `;
                    addressSuggestion.title = '';
                } else {
                    addressSuggestion.label = `From ${addressSuggestion.contacts.length} Contacts: `;
                    addressSuggestion.title = addressSuggestion.contacts.map(contact => contact.name).join(', ');
                }
                addressSuggestion.contact = addressSuggestion.contacts[0];
                delete addressSuggestion.contacts;
            }

            return addressSuggestions;
        },
        /**
         * Creates an address suggestion for the primary object using the data of the secondary object
         *
         * @param primary The object for which the suggestion is created
         * @param secondary The object which is used for the suggestion
         * @returns {string|null}
         */
        getAddressSuggestion(primary, secondary) {
            let foundAttributeToUpdate = false;
            const suggestion = [];

            for (const attribute of this.addressAttributes) {
                const primaryAttributeValue = primary[attribute];
                const secondaryAttributeValue = secondary[attribute];

                if (!primaryAttributeValue) {
                    if (secondaryAttributeValue) {
                        foundAttributeToUpdate = true;
                    }
                    suggestion.push(secondaryAttributeValue);
                } else if (primaryAttributeValue === secondaryAttributeValue) {
                    // Not interesting for updating, but should still be shown
                    suggestion.push(secondaryAttributeValue);
                } else {
                    break;
                }
            }

            // Filter empty parts of the address and then reserve for easier readability
            return foundAttributeToUpdate ? suggestion.filter(x => x).reverse().join(', ') : null;
        },
        updateContactAddressFromCompany(contact) {
            this.updateAddress(contact, contact.company);
        },
        updateCompanyAddressFromContact(company, contact) {
            this.updateAddress(company, contact);
        },
        /**
         * Updates the address of the primary object using the data of the secondary object
         *
         * @param primary The object which is updated
         * @param secondary The object which is used for the suggested data
         */
        updateAddress(primary, secondary) {
            for (const attribute of this.addressAttributes) {
                const primaryAttributeValue = primary[attribute];
                const secondaryAttributeValue = secondary[attribute];

                if (!primaryAttributeValue || (secondaryAttributeValue === primaryAttributeValue)) {
                    primary[attribute] = secondaryAttributeValue;
                } else {
                    break;
                }
            }
        }
    },
}
